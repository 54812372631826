import httpService from "@/request"

// 活动列表
export function activityList(params) {
	return httpService({
		url: `/user/activity/list`,
		method: 'get',
		params: params,
	})
}
export function organizerSelect(params) {
	return httpService({
		url: `/user/activityOrganizer/allList`,
		method: 'get',
		params: params,
	})
}
// 报名人
export function registrationList(params) {
	return httpService({
		url: `/user/activity/registrationList`,
		method: 'get',
		params: params,
	})
}
export function activityInfo(params) {
	return httpService({
		url: `/user/activity/findById`,
		method: 'get',
		params: params,
	})
}
export function activityInsert(params) {
	return httpService({
		url: `/user/activity/insert`,
		method: 'post',
		data: params,
	})
}
export function activityUpdate(params) {
	return httpService({
		url: `/user/activity/update`,
		method: 'post',
		data: params,
	})
}
export function activityDel(params) {
	return httpService({
		url: `/user/activity/delete`,
		method: 'post',
		data: params,
	})
}
