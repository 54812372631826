<template>
  <div>
    <a-modal
      width="90%"
      title="选择链接"
      :visible="show"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-table
          :columns="columns[typeMean]"
          :data-source="tableData"
          :pagination="pagination"
          :scroll="{ x: 1400 }"
          @change="handleTableChange"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: selectionChoosed,
          }"
          :row-key="
            (record, index) => {
              return record.id;
            }
          "
        >
			<span slot="content" slot-scope="text, record">
				<a-popover>
					<template slot="content">
						<div style="width: 600px">{{record.content}}</div>
					</template>
					<div>{{doStr(record.content,21)}}</div>
				</a-popover>
			</span>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { columns, pagination } from "./tableConfig.js";
import { shopPushList } from "@/api/shop/goods";
import { newsList } from "@/api/operation/news";
import { announcementList } from "@/api/operation/announcement";
import { activityList } from "@/api/operation/activity";
export default {
	props: {
		type: Number,
		show: Boolean,
		selected: Number,
	},
	data() {
		return {
			columns,
			pagination,
			typeMean: "shop",
			tableData: [],
			selectedRowKeys: [],
			joinString:''
		};
	},
	methods: {
		getData() {
			let obj = {pageNum: this.pagination.current, size: this.pagination.pageSize}
			if(this.typeMean == 'shop') {
				shopPushList(obj).then((res) => {
					this.tableData = res.data.rows;
					this.pagination.total = res.data.total;
				});
			} else if (this.typeMean == 'news') {
				newsList(obj).then((res) => {
					this.tableData = res.data.rows;
					this.pagination.total = res.data.total;
				});
			} else if (this.typeMean == 'announcement') {
				announcementList(obj).then((res) => {
					this.tableData = res.data.rows;
					this.pagination.total = res.data.total;
				});
			} else if (this.typeMean == 'activity') {
				activityList(obj).then((res) => {
					this.tableData = res.data.rows;
					this.pagination.total = res.data.total;
				});
			}
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		handleCancel(){
			this.$emit('close')
			this.selectedRowKeys = []
		},
		handleOk(){
			if(this.selectedRowKeys.length === 0){
				this.$message.error('请选择')
			}
			else if(this.selectedRowKeys.length > 1){
				this.$message.error('只能选择一条链接')
			}else{
				for(let k of this.tableData){
					if(k.id===this.selectedRowKeys[0]){
						if(this.typeMean === 'shop'){
							this.joinString = k.skuName
						}else if(this.typeMean === 'news'){
							this.joinString = k.title
						}else if(this.typeMean === 'announcement'){
							this.joinString = k.title
						}else if(this.typeMean === 'activity'){
							this.joinString = k.title
						}
					}
				}
				this.$emit('submit',this.selectedRowKeys,this.joinString)
				this.selectedRowKeys = []
			}
		},
		//截取内容
		doStr(str, number) {
			if(str.length >= number) {
				return str.substr(0,number-1) + '...' 
			} else {
				return str
			}
		}
	},
	watch: {
		type: {
			handler(val) {
				if(this.selected != null) {
					this.selectedRowKeys = [this.selected]
				}
				if (val === 2) {
					this.typeMean = 'shop'
					shopPushList({
						pageNum: this.pagination.current,
						size: this.pagination.pageSize,
					}).then((res) => {
						this.tableData = res.data.rows;
						this.pagination.total = res.data.total;
					});
				}else if (val === 3) {
					this.typeMean = 'news'
					newsList({
						pageNum: this.pagination.current,
						size: this.pagination.pageSize,
					}).then((res) => {
						this.tableData = res.data.rows;
						this.pagination.total = res.data.total;
					});
				}else if (val === 4) {
					this.typeMean = 'announcement'
					announcementList({
						pageNum: this.pagination.current,
						size: this.pagination.pageSize,
					}).then((res) => {
						this.tableData = res.data.rows;
						this.pagination.total = res.data.total;
					});
				}else if (val === 5) {
					this.typeMean = 'activity'
					activityList({
						pageNum: this.pagination.current,
						size: this.pagination.pageSize,
					}).then((res) => {
						this.tableData = res.data.rows;
						this.pagination.total = res.data.total;
					});
				}
			},
			immediate: true,
		},
	},
};
</script>

<style>
</style>
