import httpService from "@/request"

// 公告列表
export function announcementList(params) {
	return httpService({
		url: `/user/announcement/list`,
		method: 'get',
		params: params,
	})
}
export function announcementInfo(params) {
	return httpService({
		url: `/user/announcement/findById`,
		method: 'get',
		params: params,
	})
}
export function announcementInsert(params) {
	return httpService({
		url: `/user/announcement/insert`,
		method: 'post',
		data: params,
	})
}
export function announcementDel(params) {
	return httpService({
		url: `/user/announcement/delete`,
		method: 'post',
		data: params,
	})
}
export function announcementUpdate(params) {
	return httpService({
		url: `/user/announcement/update`,
		method: 'post',
		data: params,
	})
}
