<template>
  <div>
    <div class="cardTitle">首页轮播图设置</div>
    <a-divider></a-divider>
    <a-button class="add-btn" style="margin: 10px" @click="newSwiper"
      >添加轮播图</a-button
    >
	<!-- list -->
    <a-card
      hoverable
      style="width:1000px; margin-top: 10px"
      v-for="item in cardList"
      :key="item.id"
    >
      <span class="card-close" @click="del(item.id)">×</span>
      <a-row>
        <a-col :span="9">
          <img
            :src="$ImgUrl(item.imgList[0].url)"
            v-if="item.imgList.length > 0"
            class="list-img"
            alt=""
          />
          <div v-else>暂无图片</div>
        </a-col>
        <a-col :span="12">
          <a-descriptions layout="vertical" :column="1">
            <a-descriptions-item label="链接设置">
              {{ item.customizeUrl }}
            </a-descriptions-item>
            <a-descriptions-item label="是否显示在用户端">
              {{ item.isShow ? "是" : "否" }}
            </a-descriptions-item>
            <a-descriptions-item label="显示权重">
              {{ item.showWeights }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="3">
          <a-button class="add-btn" @click="edit(item)">修改设置</a-button>
        </a-col>
      </a-row>
    </a-card>
    <!-- 添加 -->
    <a-modal
      :title="add.title"
      :visible="add.show"
      @ok="saveSetting"
      @cancel="newSwiperClose"
      width="900px"
    >
      <a-row>
        <a-col :span="9">
          <div style="color: #00000073; padding-bottom: 10px;">
            上传图片尺寸: 750*300
          </div>
          <commonUpload
            :fileList="fileList"
            @handleChange="handleChange"
          ></commonUpload>
        </a-col>
        <a-col :span="15">
          <a-descriptions layout="vertical" :column="1">
            <a-descriptions-item label="链接设置">
              <a-input-group compact>
                <a-select
                  style="width: 120px"
                  v-model="form.type"
                  placeholder="请选择类型"
                  @change="changeType"
                >
                  <a-select-option :value="1"> 自定义链接 </a-select-option>
                  <a-select-option :value="2"> 商城 </a-select-option>
                  <a-select-option :value="3"> 资讯 </a-select-option>
                  <a-select-option :value="4"> 公告 </a-select-option>
                  <a-select-option :value="5"> 活动 </a-select-option>
                </a-select>
                <a-input
                  v-if="form.type === 1"
                  style="width: 200px; background: #0000000f"
                  placeholder="请输入链接"
                  v-model="form.customizeUrl"
                ></a-input>
                <a-button
                  v-else-if="form.type !== 1 && form.customizeUrl === ''"
                  style="width: 100px; background: #0000000f"
                  @click="table.show = true"
                  >请选择链接
                </a-button>
                <a-button
                  style="width: 70%; background: #0000000f; overflow: hidden"
                  @click="table.show = true"
                  v-else
                  >{{ form.customizeUrl }}</a-button
                >
              </a-input-group>
            </a-descriptions-item>
            <a-descriptions-item label="是否显示在用户端">
              <a-switch
                checked-children="开"
                un-checked-children="关"
                v-model="form.isShow"
              ></a-switch>
            </a-descriptions-item>
            <a-descriptions-item label="显示权重">
              <a-input
                type="number"
                v-model.number="form.showWeights"
              ></a-input>
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
    </a-modal>

    <chooseTable
      :show="table.show"
      :type="form.type"
	  :selected="selected"
      @submit="submitURL"
      @close="close"
    />
  </div>
</template>

<script>
import { form } from "./depend/config";
import {
	swiperList,
	swiperInsert,
	swiperDel,
	swiperUpdate,
} from "@/api/operation/swiper";
import chooseTable from "./depend/table.vue";
export default {
	components: {
		chooseTable,
	},
	inject: ["reload"],
	data() {
		return {
			table: {
				show: false,
			},
			fileList: [],
			cardList: [],
			add: {
				show: false,
				title: "添加轮播图",
			},
			form,
			selected: undefined,
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			let res = await swiperList();
			this.cardList = res.data;
		},
		// 新增
		newSwiper() {
			this.add = {
				show: true,
				title: "添加轮播图",
			};
		},
		// 保存
		async saveSetting() {
			if (this.add.title === "添加轮播图") {
				let res = await swiperInsert(this.form);
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.newSwiperClose();
					this.getData();
					this.reload();
				} else {
					this.$message.error(res.msg);
				}
			} else if (this.add.title === "修改轮播图") {
				let res = await swiperUpdate(this.form);
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.getData();
					this.newSwiperClose();
				} else {
					this.$message.error(res.msg);
				}
			}
		},
		// 编辑
		edit(data) {
			this.add = {
				show: true,
				title: "修改轮播图",
			};
			this.form.id = data.id;
			this.form.isShow = data.isShow;
			this.form.showWeights = data.showWeights;
			this.form.type = data.type;
			this.form.customizeUrl = data.customizeUrl;
			this.selected = data.associationId;
			this.form.associationId = data.associationId;
			if (data.imgList.length > 0) {
				// 如果已经有图片
				this.form.imgUrls.push(data.imgList[0].url);
				const file = [];
				for (let item of data.imgList) {
					let obj = {
						name: item.url.split("_")[0] + "." + item.url.split(".")[1],
						url: this.$ImgUrl(item.url),
						uid: item.url.split("_")[1],
						status: "done",
						thumbUrl: this.$ImgUrl(item.url),
					};
					file.push(obj);
				}
				this.fileList = file;
			}
		},
		newSwiperClose() {
			this.add = {
				show: false,
				title: "添加轮播图",
			};
			this.fileList = [];
			this.form.imgUrls = [];
			this.form = {
				id: undefined,
				type: undefined,
				showWeights: 0,
				customizeUrl: "",
				isShow: false,
				associationId: undefined,
				imgUrls: [],
			};
		},
		// 选择链接确定
		submitURL(data, customizeUrl) {
			this.form.customizeUrl = customizeUrl;
			this.form.associationId = data[0];
			this.close();
		},
		// 关闭选择
		close() {
			this.table.show = false;
		},
		changeType() {
			this.form.customizeUrl = "";
		},
		// 删除轮播图
		async del(id) {
			this.$confirm({
				title: "是否删除",
				icon: "delete",
				onOk: async () => {
					swiperDel({ homepageCarouselId: id }).then((res) => {
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.getData();
						} else {
							this.$message.error(res.msg);
						}
					});
				},
			});
		},
		// 上传图片
		// handleChange(data) {
		// 	console.log(data);
		// 	this.fileList = data;
		// 	// 删除照片
		// 	if (this.fileList.length === 0) {
		// 		this.form.imgUrls = [];
		// 	} else if (data[0].status === "done") {
		// 		this.form.imgUrls.push(data[0].response.data);
		// 	}
		// },
		handleChange(data) {
			this.fileList = data;
			this.form.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.form.imgUrls.push(ele.response.data)
				}
			})
		},
	},
};
</script>

<style lang="less" scoped>
/deep/.ant-upload.ant-upload-select-picture-card {
  width: 90%;
  height: 200px;
}
/deep/.ant-upload-list-picture-card .ant-upload-list-item {
  width: 90%;
  height: 200px;
}
/deep/.ant-upload-list-picture-card-container {
  width: 90%;
  height: 200px;
}
.list-img {
  width: 300px;
  height: 200px;
}
.card-close {
  position: absolute;
  right: 10px;
  top: -3px;
  cursor: pointer;
  font-size: 20px;
}
</style>
