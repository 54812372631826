export const columns = {
	shop:[
		{
			title: "商品名称",
			width: "12%",
			dataIndex: "skuName",
		},
		{
			title: "sku编码",
			width: "12%",
			dataIndex: "skuId",
		},
		{
			title: "商品类型",
			width: "7%",
			dataIndex: "mallType",
			customRender: function (mallType) {
				switch (mallType) {
				case 1:
					return 'Jcook'
					break;
				default:
					break;
				}
			}
		},
		{
			title: "店铺名",
			width: "8%",
			dataIndex: "shopName",
		},
		{
			title: "品牌名",
			width: "8%",
			dataIndex: "brandName",
		},
		{
			title: "供应商名",
			width: "8%",
			dataIndex: "vendorName",
		},
		{
			title: "一级分类名称",
			width: "8%",
			dataIndex: "categoryFirstName",
		},
		{
			title: "二级分类名称",
			width: "8%",
			dataIndex: "categorySecondName",
		},
		{
			title: "三级分类名称",
			width: "8%",
			dataIndex: "categoryThirdName",
		},
		{
			title: "主图url",
			width: "8%",
			dataIndex: "mainPhoto",
		},
		{
			title: "售卖价",
			width: "8%",
			dataIndex: "sellPrice",
		},
		{
			title: "折扣价",
			width: "8%",
			dataIndex: "discountPrice",
		},
		{
			title: "浏览量",
			width: "8%",
			dataIndex: "viewsNum",
		},
	],
	news: [
		{
			title: "标题",
			dataIndex: "title",
		},
		{
			title: "分类",
			dataIndex: "categoryName",
		},
		{
			title: "发布状态",
			dataIndex: "status",
			customRender: function (status) {
				switch (status) {
				case 1:
					return '未发布'
				case 2:
					return '已发布'
				}
			}
		},
		{
			title: "阅读量",
			dataIndex: "viewsNum",
		},
		{
			title: "创建时间",
			dataIndex: "createDate",
		},
	],
	announcement:  [
		{
			title: "标题",
			dataIndex: "title",
			width: "16%",
		},
		{
			title: "推送对象",
			dataIndex: "object",
			width: "10%",
			customRender: function (object) {
				switch (object) {
				case 1:
					return '全部'
					break;
				case 2:
					return '住户'
					break;
				case 3:
					return '业主'
					break;
				case 4:
					return '租户'
					break;
				case 5:
					return '管家'
					break;
				default:
					break;
				}
			}
		},
		{
			title: "状态",
			dataIndex: "status",
			width: "10%",
			customRender: function (status) {
				switch (status) {
				case 1:
					return '未发布'
					break;
				case 2:
					return '已发布'
					break;
				default:
					break;
				}
			}
		},
		{
			title: "内容",
			dataIndex: "content",
			width: "13%",
			scopedSlots: { customRender: "content"}
		},
		{
			title: "阅读量",
			dataIndex: "readingVolume",
			width: "10%",
		},
		{
			title: "附件下载次数",
			dataIndex: "downloadNum",
			width: "10%",
		},
		{
			title: "更新时间",
			dataIndex: "modifyDate",
			width: "14%",
		}
	],
	activity:[
		{
			title: "发布时间",
			dataIndex: "createDate",
			width: "12%",
		},
		{
			title: "图片",
			dataIndex: "imgpic",
			width: "10%",
			scopedSlots: { customRender: "imgpic"}
		},
		{
			title: "活动状态",
			dataIndex: "status",
			width: "6%",
			customRender: function (status) {
				switch (status) {
				case 1:
					return '报名未开始'
				case 2:
					return '报名进行中'
				case 3:
					return '活动未开始'
				case 4:
					return '活动进行中'
				case 5:
					return '活动已结束'
				}
			}
		},
		{
			title: "活动标题",
			dataIndex: "title",
			width: "8%",
		},
		{
			title: "活动地点",
			dataIndex: "activityAddress",
			width: "8%",
		},
		{
			title: "是否显示在app",
			dataIndex: "isShow",
			width: "5%",
			customRender: function (isShow) {
				switch (isShow) {
				case true:
					return '显示'
				case false:
					return '不显示'
				}
			}
		},
		{
			title: "主办方",
			dataIndex: "organizerName",
			width: "8%",
		},
		{
			title: "活动内容",
			dataIndex: "content",
			width: "8%",
		},
		{
			title: "报名人数",
			dataIndex: "registrationNum",
			width: "5%",
		},
		{
			title: "报名时间",
			width: "12%",
			customRender: function (data) {
				return (data.registrationStartTime + '——' + data.registrationEndTime)
			}
		},
		{
			title: "活动时间",
			width: "12%",
			customRender: function (data) {
				return (data.activityStartTime + '——' + data.activityEndTime)
			}
		}
	]
}
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 5,
	showTotal: (total) => `共 ${total} 条`,
}