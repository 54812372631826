import httpService from "@/request"

// 轮播图列表
export function swiperList(params) {
	return httpService({
		url: `/user/homepageCarousel/list`,
		method: 'get',
		params: params,
	})
}
// 删除
export function swiperDel(params) {
	return httpService({
		url: `/user/homepageCarousel/delete`,
		method: 'get',
		params: params,
	})
}
// 新增
export function swiperInsert(params) {
	return httpService({
		url: `/user/homepageCarousel/insert`,
		method: 'post',
		data: params,
	})
}
// 修改
export function swiperUpdate(params) {
	return httpService({
		url: `/user/homepageCarousel/update`,
		method: 'post',
		data: params,
	})
}

